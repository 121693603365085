table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 16px;
}

tr {
  background-color: white;
}

thead tr {
  background-color: #282c34;
  color: white;
}

tr:nth-child(even) {
  background-color: #d9d9d9;
}

th,
td {
  padding: 6px;
  text-align: center;
}

tr td:last-child {
  display: flex;
  justify-content: end;
}

.modal-frame {
  background-color: #00000098;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.modal {
  position: relative;
  width: 80%;
  max-width: 400px;
  min-height: 300px;
  background-color: white;
  box-shadow: 0 0 6px black;
  border-radius: 5px;
  margin: auto;
  animation: slide-bottom 0.555s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.modal h2 {
  color: #282c34;
}

.modal .close {
  position: absolute;
  right: 0;
  top: 0;
  margin: 3px;
  width: initial;
  background: none;
  color: #373737;
  font-size: 22px;
  padding: 8px 16px;
  transition: 0.3s;
  cursor: pointer;
}

.modal .close:hover {
  background-color: #af0000;
  color: white;
  border-radius: 50%;
  rotate: 360deg;
}

.modal form {
  padding: 24px;
}

@keyframes slide-bottom {
  0% {
    transform: translateY(-200px);
  }

  100% {
    transform: translateY(100px);
  }
}

.addBtn {
  width: initial;
}
